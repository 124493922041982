import * as React from "react";
import { Heading, Text, Image, Flex, Box } from "rebass";
import Page from "../components/Page";
import IndexLayout from "../layouts";
import { Helmet } from "react-helmet";
import SiteFooter from "../components/SiteFooter";

import * as styles from "./Story.module.css";

import stephenProfile from "../assets/images/stephen.png";
import stephenImage1 from "../assets/images/stephen-image-1.jpg";
import stephenImage2 from "../assets/images/stephen-image-2.jpg";
import stephenImage3 from "../assets/images/stephen-image-3.jpg";
import storyIcon1 from "../assets/images/story-icon-1.png";
import storyIcon2 from "../assets/images/story-icon-2.png";
import storyIcon3 from "../assets/images/story-icon-3.png";
import storyIcon4 from "../assets/images/story-icon-4.png";

import HeroLayoutEmpty from "../components/HeroLayoutEmpty";

import HeroGridCard from "../components/HeroGridCard";
import HeroGrid from "../components/HeroGrid";

const StephenStoryPage = () => {
  return (
    <IndexLayout>
      <Page>
        <HeroGrid
          style={{ position: "relative", overflow: "hidden", rowGap: "0" }}
        >
          <Helmet>
            <title>Skodel - Stephen's Story</title>
            <meta property="og:title" content="Skodel - Stephen's Story" />
            <meta
              property="og:description"
              content="Read Stephen's story, how Skodel supports Learnlife and why he is passionate about this area"
            />
          </Helmet>
          <HeroGridCard
            className={styles.storyStephenContainer}
            color="white"
            style={{ maxWidth: "unset" }}
          >
            <Box className={styles.heroStoryStephenStripes}>
              <Box className={styles.heroStoryStephenStripesInner}>
                <Box className={styles.heroStoryStephenStripe1} />
                <Box className={styles.heroStoryStephenStripe2} />
                <Box className={styles.heroStoryStephenStripe3} />
              </Box>
            </Box>
            <HeroLayoutEmpty className={styles.storyStephenWrapper}>
              <Flex justifyContent="center">
                <Box className={styles.storyStephenContent}>
                  <Box className={styles.storyStephenImage}>
                    <Image
                      src={stephenProfile}
                      alt="A photo of Stephen Harris"
                    />
                  </Box>
                  <Heading className={styles.storyStephenName}>
                    Stephen Harris
                  </Heading>
                  <Heading className={styles.storyStephenOccupation}>
                    Former Principal and now Chief Learning Officer
                  </Heading>
                  <Heading className={styles.storyStephenOccupationBig}>
                    Key Takeaway from Skodel: Timely and useful wellbeing data
                  </Heading>
                  <Text className={styles.storyStephenMessage}>
                    It is often said that education is in need of transformation
                    as traditional models no longer meet the needs of 21C
                    learners. And in a world where 'disruption' seems to be the
                    standard, it remains ever difficult to drive real change in
                    our education models. For 40 years, Stephen Harris educated
                    and inspired children within traditional forms of schooling,
                    19 of those as a Principal before arriving "at a point where
                    he felt the future of learning would require fresh
                    creativity to imagine new scenarios and contexts for
                    learning - and that those wouldn't be ‘within the system’".
                    Stephen's journey to reimagine education has taken him
                    around the world, working with students from vastly
                    different backgrounds, helping equip them with the life
                    skills and belief they need to grow into resilient, purpose
                    driven individuals with a lifelong curiosity for learning.
                    Most recently, Stephen has been the driving force behind
                    Learnlife, a fresh model for learning that is suited to the
                    rapidly changing global contexts. Stephen is a true pioneer.
                    We asked asked him to share his journey in education and the
                    thinking behind Learnlife.
                  </Text>
                  <Box className={styles.storySparator}>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon1} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        What inspired you to become a teacher?
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        I attended 6 different schools as a child/teenager
                        across three countries (NZ, UK, Australia) and finished
                        up really disliking schools and schooling. My best year
                        at a school was when I was 11. I was enrolled in the NZ
                        correspondence school for a year because our family was
                        going to the UK for a year. I recall managing almost all
                        of the year’s work on board the 38 day boat journey from
                        Auckland to Southampton via multiple countries – and
                        essentially taking the rest of the year off. That gave
                        plenty of time to explore castles, the New Forest, the
                        coast – that is where the real learning took place.
                      </Text>
                      <Text className={styles.storyGroupDescription}>
                        By the time I was in Year 12 in Sydney, I did not like
                        schooling at all – and was an excellent &amp; undetected
                        truant. I did have one fantastic teacher for English –
                        and more than anyone else, she inspired me with a love
                        of literature and somehow I managed to do extremely well
                        in that subject in particular. It was at the time where
                        university was free – and also offering scholarships to
                        become a teacher, so without really thinking about it
                        too much, I enrolled.
                      </Text>
                      <Text className={styles.storyGroupDescription}>
                        Right from that point I recognised that schooling could
                        be fun if the learner was placed at the centre of the
                        process and that it was up to us as the adults to adjust
                        anything and everything to ensure high enjoyment,
                        curiosity and emotional well-being.
                      </Text>
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon2} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Describe one moment that made you realise how special it
                        is to be a teacher.
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        There are many moments. I always felt it a privilege as
                        a school Principal to become the quiet advocate for the
                        kids who struggled with teachers, learning, school and
                        sometimes their families. When those people start to
                        turn a corner and gain (or regain) a curiosity for
                        learning, often hand in hand with beginning to ‘like’
                        themselves, there is a strong sense that everything you
                        do is very worthwhile.
                      </Text>
                      <Text className={styles.storyGroupDescription}>
                        Similarly, I should mention that my experience as a
                        teacher has also taken me to Rwanda. Over a twelve year
                        period I have watched kids who were initially upper
                        primary age grow into amazing lifelong learners – many
                        attending university. Many have come from some
                        incredibly challenging economic backgrounds – but as you
                        watch the gaining of an education help shift not only
                        that person, but most commonly their entire families,
                        into stronger circumstances financially, you can see
                        that a quality and highly relational education is the
                        answer to many of the world’s challenges.
                      </Text>
                      <Image
                        className={styles.storyImage}
                        src={stephenImage1}
                        alt=""
                      />
                      <Text className={styles.storyGroupDescription}>
                        There is one story though that stands out. I met a boy
                        (young teens at the time) who is totally deaf and mute.
                        He was living on the fringes of his village and could
                        not attend school as no one could relate to him. I saw
                        that once he was able to access suitable education, he
                        has surged forward in every way, essentially completing
                        about 8 years of schooling in four years. When I
                        reconnect with him, you see the incredible privilege it
                        is to be a teacher.
                      </Text>
                      <Image
                        className={styles.storyImage}
                        src={stephenImage2}
                        alt=""
                      />
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon4} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        As co-founder and director of learning at Learnlife, you
                        have played an integral role in shaping what is a unique
                        educational model, can you describe the thinking behind
                        it and in particular, the why.
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        I have always felt that a school experience will only be
                        negative if we as adults are not prepared to daily
                        adjust anything that impedes the enjoyment of, or
                        engagement into, learning. When the opportunity arose to
                        join with people from the entrepreneurial world and
                        connect that directly with ‘education as it could be’,
                        there was no thought other than to jump on board. The
                        first version of ‘Learnlife’ was called ‘Our Dream
                        School’, but as that dream became a reality, the name
                        was changed to suggest the focus on life and lifelong
                        learning.
                      </Text>
                      <Text className={styles.storyGroupDescription}>
                        In terms of the model at Learnlife, having chosen to
                        target a program for post compulsory schooling, we were
                        essentially free to make everything up. We were ‘outside
                        the system’. Our thinking has always been to create the
                        wider framework for creating a learning community within
                        a wider ecosystem where a positive culture of learning
                        was deeply embedded and was the subliminal driver for
                        everything. Learnlife has been described as emergent
                        mainstream – and it is very much guided by an
                        understanding and aggregation of neuroscience research
                        and aggregated best practice.
                      </Text>
                      <Text className={styles.storyGroupDescription}>
                        Why do this? The more we can show how the threads of
                        change can come together, people can more readily gain a
                        vision for learning that breaks out of the default
                        traditional model. I also have a strong belief that
                        ‘schools’ should be integrated into the wider society –
                        so at Learnlife, the community involves multiple people
                        of all ages and backgrounds growing, working and
                        learning together.
                      </Text>
                      <Image
                        className={styles.storyImage}
                        src={stephenImage3}
                        alt=""
                      />
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon3} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Why did you decide to bring Skodel in to Learnlife?
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        I have always known that well-being and real learning go
                        together. Kids are not going to thrive as learners if
                        they do not feel safe or there is some other issue. I
                        have had a growing sense that well-being has still been
                        somewhat marginalised within school routines, even when
                        introduced as a program, or with dedicated staff. It
                        needs to be at the foundation of all decision making.
                        Will this schedule or decision impact the learner’s
                        well-being? When I became aware of Skodel, it
                        immediately appealed because it can produce real time
                        information useful for everyone in the community – and
                        do so with very little effort. For that reason, I am
                        very keen to see Skodel integrated with daily practice
                        at Learnlife.
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <SiteFooter />
        </HeroGrid>
      </Page>
    </IndexLayout>
  );
};

export default StephenStoryPage;
